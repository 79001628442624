import React, {Component} from 'react';
import styled from 'styled-components'
import {media} from "utils/Media"
import Slider from 'react-slick'
import {graphql, StaticQuery} from "gatsby";
import YoutubeModal from "../../VideoModal/YoutubeModal"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import arrowRight from '../../../images/carousel-arrow-right.png'
import arrowRightXs from '../../../images/arrow-right-xs.svg'
import HeadingSpecial from "../../shared/HeadingSpecial";
import playIcon from "../../../images/icon_largeplay.svg"
import {GatsbyImage, getImage} from "gatsby-plugin-image";

const Section = styled.div`
    margin: 3rem auto 5rem;
    
    @media ${media.xl} {
        margin: 4rem auto 7rem;
    }
    
    h5 {
        margin-top: 2rem;
    }
    
    .content {
        max-width: 740px;
        margin: 5rem auto 0 !important;
        padding: 0 15px;
        
        @media ${media.sm} {
            padding: 0;
        }
    }
`

const PhotoSlider = styled(Slider)`
    position: relative;
    z-index: 2;
    margin: 0 auto;
    max-width: 580px;
    
    @media ${media.lg} {
        max-width: 800px;
    }
    
    @media ${media.xl} {
        max-width: 1048px;
    }
    
    .playIcon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 3;
    }

    .slick-track {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        justify-content: center;
    }
    
    .slick-slide{
        padding: 0;
        transition: all 0.3s ease;
        height: auto;
        
        &.slick-current,
        &.slick-center {
          opacity: 1 !important;
          
        }
        opacity: 0.2;
      
    }
    
    .photo-wrapper {
        position: relative;
        max-width: 640px;
        cursor: pointer;
        
        @media ${media.xl} {
            max-width: 1048px;
        }
        
    }
    
    .slick-dots {
        bottom: -25px;
        z-index: 9;
        
        @media ${media.md} {
            bottom: -35px;
        } 
        
        @media ${media.lg} {
            bottom: -50px;
        } 
    }
    
    .slick-dots li, .slick-dots li button {
        width: 10px;
        height: 10px;
        
        @media ${media.md} {
            width: 12px;
            height: 12px;
        }
        
        @media ${media.lg} {
            width: 20px;
            height: 20px;
        }
    }
    
    .slick-dots li button:before {
        color: ${props => props.theme.colors.turquoise2};
        opacity: 1;
        font-size: 10px;
        
        @media ${media.md} {
            font-size: 12px;
        }
        
        @media ${media.lg} {
            font-size: 20px;
        }
    }
    
    .slick-dots li.slick-active button:before {
        color: transparent;
        border: 3px solid #63DBE7;
        border-radius: 5px;
        top: 4px;
        left: 5px;
        width: 10px;
        height: 10px;
        
        @media ${media.md} {
            top: 3px;
            border-radius: 6px;
            width: 12px;
            height: 12px;
        }
        
        @media ${media.lg} {
            left: 0;
            top: -1px;
            border-radius: 10px;
            width: 20px;
            height: 20px;
        }
    }
    .slick-prev, .slick-next {
        width: 16px;
        height: 32px;
        bottom: -50px;
        top: initial;
        z-index: 10;
        
        @media ${media.md} {
             width: 50px;
            height: 50px;
            top: 175px;
            bottom: initial;
        }
        
        @media ${media.lg} {
            top: 50%;    
        } 
        
        &:focus {
            outline: 5px auto -webkit-focus-ring-color !important;
        }
    }
    
    .slick-next {
        right: 20px;
        
        @media ${media.md} {
            right: -80px;
        }
        
        &:before {
            content: '';
            background: url(${arrowRightXs}) no-repeat center center;
            background-size: contain;
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
            height: 100%;
            
             @media ${media.md} {
                background: url(${arrowRight}) no-repeat center center;
                background-size: contain;
             }
        }
    }
    
    .slick-prev {
        left: 20px;
        
        @media ${media.md} {
            left: -80px;
        }
        
        &:before {
            content: '';
            transform: rotate(180deg);
            background: url(${arrowRightXs}) no-repeat center center;
            background-size: contain;
            position: absolute;
            left: 0;
            top: 6px;
            width: 100%;
            height: 100%;
            
            @media ${media.md} {
                background: url(${arrowRight}) no-repeat center center;
                background-size: contain;
                top: 0;
             }
        }
    }
    
`

const Query = (props) => (
    <StaticQuery
        query={graphql`
            query {
                bgImage: file(relativePath: { eq: "EducationPage/menu-bg.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100, placeholder: NONE
                            formats: [AUTO, WEBP])
                    }
                }
            }
		`}
        render={data => (
            <CarouselSection
                data={data}
                carouselData={props.carouselData}
            />
        )}
    />
)

class CarouselSection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalOpen: false,
            videoId: '',
        }
    }

    toggleModal = (e) => {
        e.preventDefault();

        this.setState({
            modalOpen: !this.state.modalOpen
        });
    };


    sendTealium = (currentSlide, slideCount, which) => {
        let sliderId

        if (which === 'right') {
            this.carousel.slickNext();
            sliderId = currentSlide + 1
            if (sliderId === slideCount) {
                sliderId = 0
            }
        } else {
            this.carousel.slickPrev();
            sliderId = currentSlide + -1

            if (sliderId < 0) {
                sliderId = slideCount - 1
            }
        }

        if (typeof window !== 'undefined' && typeof window.utag !== 'undefined') {
            // Tealium
            let linkData = {
                siteName: "frozeneducation",
                country: "uk",
                region: "emea",
                actionName: "navButtonClick",
                actionValue1: `music carousel ${sliderId}`
            }
            console.log(`music carousel ${sliderId}`)
            window.utag.link(linkData)
        }
    }

    render() {
        const {modalOpen, videoId} = this.state;
        const SlickArrowLeft = ({currentSlide, slideCount, ...props}) => (
            <button
                {...props}
                className={
                    "slick-arrow slick-prev"
                }
                aria-hidden="false"
                aria-disabled={currentSlide === 0}
                type="button"
                tabIndex={0}
                onClick={() => this.sendTealium(currentSlide, slideCount, 'left')}
            >
                Previous
            </button>
        );
        const SlickArrowRight = ({currentSlide, slideCount, ...props}) => (
            <button
                {...props}
                className={
                    "slick-arrow slick-next"
                }
                aria-hidden="false"
                aria-disabled={currentSlide === slideCount - 1}
                type="button"
                tabIndex={0}
                onClick={() => this.sendTealium(currentSlide, slideCount, 'right')}
            >
                Previous
            </button>
        );
        const settings = {
            centerMode: true,
            slidesToShow: 1,
            centerPadding: '0',
            dots: true,
            arrows: true,
            prevArrow: <SlickArrowLeft/>,
            nextArrow: <SlickArrowRight/>,
            cssEase: 'linear',
            draggable: true,
            swipe: true,
            swipeToSlide: true,
            touchMove: true,
            accessibility: true,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        centerPadding: '0',
                    }
                }
            ]
        }

        const items = this.props.carouselData;
        const galleryItems = items.map(({node}, id) => {
            console.log(node)
            return (
                <div
                    key={id}
                >
                    <div className="video-wrapper" tabIndex={0} role="button"
                         onKeyDown={() => this.setState({videoId: node.videoId, modalOpen: true})}
                         onClick={() => this.setState({videoId: node.videoId, modalOpen: true})}>
                        <GatsbyImage placeholder="none"
                                     image={getImage(node.image)}
                                     alt={node.name}
                                     title={node.name}
                                     className="img-fluid posterImage"
                        />
                        <img src={playIcon} className="playIcon" alt="Play video" title="Play video"/>

                    </div>
                    <h5 className="font--regular color--navy font-weight-bold d-none d-md-block">{node.name}</h5>
                    <HeadingSpecial lines={false} headingTag="h5"
                                    className="color--navy d-md-none font--bigger mt-3"
                                    title={node.name}/>
                </div>
            )

        })
        return (
            <Section bgImage={this.props.bgImage}>
                <PhotoSlider
                    {...settings}
                    className="PhotoGallery"
                    ref={slider => (this.carousel = slider)}
                >
                    {galleryItems}
                </PhotoSlider>

                <div className="content">
                    <h4 className="font--regular mb-4">
                        Expanding on their seven songs from the film, songwriters Kristen Anderson-Lopez and Robert Lopez wrote an additional 12 songs for the stage adaptation. Many of these songs focus on deepening the characters of Anna, Elsa, Hans and Kristoff and their relationships with each other.
                    </h4>
                    <h4 className="font--regular mb-4">
                        In addition to the contemporary ‘West End Musical’-styled sounds of the score, the music draws inspiration from the story’s setting. The Hidden Folk perform a joik, which is a traditional S&aacute;mi form of song. Joiking is one of the longest living music traditions in Europe, dating back centuries. The music is often interpreted not to be about a person or place, but instead invoking that person or place. It does not need to include words, but can weave a narrative through words, melody, rhythm, expressions or gestures. Examples of joik in FROZEN are the “Vuelie” theme heard at the very beginning of the show, and the call that both Queen Iduna and Kristoff make to summon the Hidden Folk.
                    </h4>
                </div>
                <YoutubeModal isOpen={modalOpen} toggleModal={this.toggleModal} videoId={videoId}/>
            </Section>

        );
    }
}

export default Query;