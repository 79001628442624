import React, {Component} from "react"
import SEO from "utils/Seo"
import Layout from "components/Layout/Layout"
import MoreTopics from "../../../components/Education/MoreTopics"
import styled from "styled-components";
import HeadingSpecial from "../../../components/shared/HeadingSpecial";
import {Container} from "reactstrap";
import CarouselSection from "../../../components/Education/Music/CarouselSection";
import {graphql, StaticQuery} from "gatsby";
import SnowFlakes from "../../../components/shared/SnowFlakes";

const pageSlug = "music"
const pageTitle = "Music"
const categorySlug = "adaptation"
const categoryTitle = "Adaptation"

const Content = styled.div`
    position: relative;
    
    text-align: center;
`

const Query = (props) => (
    <StaticQuery
        query={graphql`
            query {
                allMusicsCarouselJson {
                    edges {
                        node {
                            name
                            image {
                                childImageSharp {
                                    gatsbyImageData(width: 1048, quality: 100)
                                }
                            }
                            videoId 
                        }
                    }
                }
            }
		`}
        render={data => (
            <Music data={data}/>
        )}
    />
)

class Music extends Component {
    constructor(props) {
        super(props);

        this.pageData = {
            pageName: pageSlug,
            siteName: "frozeneducation",
            country: "uk",
            region: "emea"
        }
    }

    componentDidMount() {
        // if (typeof window !== 'undefined' && typeof window.utag !== 'undefined') {
        //     // Tealium
        //     window.utag.view(this.pageData)
        // }
    }

    render() {

        return (
            <Layout categorySlug={categorySlug}
                    category={categoryTitle}
                    currentPage={pageTitle}
                    pageName={pageSlug}
                    mainCategorySlug="beyond-the-story"
                    enrichmentHeader>
                <SEO slug={pageSlug} title={pageTitle} description=""/>
                <SnowFlakes>
                <Content className="enrichment">
                        <HeadingSpecial lines={false} headingTag="h1"
                                        className="py-4 py-md-5 font--bigger"
                                        title={pageTitle}/>

                        <Container fluid={true}>
                            <div className="content">
                                <p className="font--regular font-weight-bold">Teachers, take your experience further. <a
                                    href="/pdf/FROZEN_LessonPlan_ImFree.pdf" target="_blank"
                                    className="link no-interstitial-check">Download&nbsp;this&nbsp;lesson&nbsp;plan</a> exploring the power of lyrics.</p>
                            </div>
                        </Container>
                        <Container fluid={true} className="no-padding-xs">
                            <CarouselSection carouselData={this.props.data.allMusicsCarouselJson.edges}/>
                        </Container>
                </Content>
                    <div className="py-5">&nbsp;</div>
                </SnowFlakes>
                <MoreTopics inspiration adaptation/>
            </Layout>
        )
    }
}
export default Query